<template>
  <div id="app">
    <Header />
    <router-view class="main-content"></router-view>
  </div>
</template>

<script>
    import Header from '../components/HeaderComponent.vue';

export default {
  name: 'App',
  components: {
    Header,
  },
}
</script>

<style lang="scss">
  @import "../assets/css/colors";
  @import "../assets/css/fonts";

#app {
  font-family: $font-main;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text-primary;
}
</style>
